import Header from "../../Components/Common/Header";
import Footer from "../../Components/Common/Footer";
import React, { useContext, useState, useEffect } from "react";


const ChatsOn = () => {
    const [currentChat, setCurrentChat] = useState(0);
    const [messages, setMessages] = useState([]);

    return (
        <>
        <Header />
        
        

        <Footer/>
        </>
    )
}

export default ChatsOn;